@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap');


.carousel-heading {
    color:var(--third-color);
}
.carousel-p {
    color: rgb(216, 216, 216);
    text-transform: capitalize;
    font-size: 12px;
}
.carousel-inner {
    text-align: center;
    height: 50vh;
}
.carousel-item {
    text-shadow: 1px 2px 30px rgba(53, 53, 53, 0.3);
    z-index: 2000; 
    margin-top: 10rem;
}
.carousel-h3 {
    /* font-size: 2rem; */
    color: rgb(245, 245, 245);
    text-transform: capitalize;
    z-index: 10000;
    font-family: 'Lobster', cursive;

}
.carousel-item {
    margin-top: 10rem
}
.carousel-control-prev,.carousel-control-next {
    margin-top: 10rem;
}

.carousel-btn {
    margin-top: 10rem;
}