* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Open Sans',Helvetica,Arial, sans-serif;
    
}
/* 
@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('Segoe UI.woff') format('woff');
    }
@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('Segoe UI Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('Segoe UI Bold.woff') format('woff');
    }
     */

 
body::-webkit-scrollbar {
    display:"none";
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&family=Readex+Pro&family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&family=Readex+Pro:wght@300;400&family=Rubik&display=swap');

.bold-text {
    font-size: 54px;
    font-weight: 500;
}
.banner-text {
    font-size: 18px;
}
.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.paragraph-wwe {
    font-size: 16px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: center;
}
.paragraph-wd {
    font-size: 15px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: center;
}
.paragraph-wdj {
    font-size: 15px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: justify;
}
.paragraph-srv {
    font-size: 15.5px;
    /* color: rgb(66, 66, 66); */
    color: #272727;
    text-align: center;
}
.paragraph-thickblue {
    font-size: 15px;
    color: rgb(41, 29, 70);
    text-align: justify;
} 
.three-head-h1 {
    color: #301c58;
    font-weight: 700;
}
.text {
    position: absolute;
    top: 10%;
    color: gainsboro;
    /* z-index: ; */
    /* background-color: rgba(0, 0, 0,.); */
    width: 100%;
    height: 100vh;
}
 video {
     position: relative;
     width: 100%;
     height: 100vh;
     object-fit: cover;
     transition: all 1.2s linear;
 }
 /* .video1 {
     opacity: 1;
     z-index: -3;
 } */

 .video2 {
    opacity: 0;
}
.video3 {
    opacity: 0;
}

/* .container-fluid1 {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0, 0, 0.4);
    text-align: center;
} */

.navbar {
    z-index: 20000;
    position: fixed;
    top: 0px;
    z-index: 5000000;
    /* left: 6.5rem; */
    /* background: #1d1d1d; */
    transition: .4s all;
}
.navbar-h1 {
    color: seashell;
}
.nav-link {
    color: seashell;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 500;
    /* margin-left: 2rem; */
    letter-spacing: 1px;
    border: none;
    transition: .3s all;
    /* margin-right: 100px; */
    color: rgb(231, 228, 225);
    cursor: pointer;
}
.nav-link:hover {
    color: skyblue;
}
.navbar-collapse {
    width: 30%;
} 
.text-none {
    text-decoration: none;
}
.seltam-brand {
    font-size: 21px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(255, 10, 182);
}
/* .nav-link:hover {
    border-bottom: 1px solid seashell;
    color: seashell;
} */

.overlay {
    height: 100vh;
    width: 100%;
    /* background-color: rgba(71, 71, 71, 0.4); */
    background: linear-gradient(to left bottom,rgba(27, 24, 24, 0.8), rgba(165, 165, 167, 0.1));
    z-index: 1;
    position: absolute;
    top: 0px;
}
.nav-all-content {
    position: absolute;
    top: 0px;
}
.nav-btn {
    margin-left: 4rem;
    width: 150px;
    background-color: transparent;
    outline: seashell 1px solid;
    color: seashell;
    padding: 10px 0px;
    font-family: 'Readex Pro', sans-serif;
    border: none;
    transition: .3s all;
}
.nav-btn:hover {
    width: 150px;
    background-color: seashell;
    outline: seashell 1px solid;
    color: rgb(248, 94, 23);
    padding: 10px 0px;
    border: none;
}
/* .nav-all-content {
 
} */


span {
    font-size: 41px;
}

.below-container {
    color: #255bf7;
    font-weight: 800;
    font-size: 58px;
}
.below-container-wd {
    color: #255bf7;
    font-weight: 600;
    font-size: 45px;
}
.below-container-2 {
    margin-top: -10px;
    font-weight: 800;
    color: #401793;
    font-size: 40px;
}

.third {
    position: relative;
    top: 0%;
    overflow: hidden;
}

.water-mark-1 {
    font-weight: bold;
    font-size: 3rem;
     position: absolute; 
    top: 53px; 
    left: 50px;
    z-index: 0;
    opacity: .2;
    transform: rotateZ(0deg);
    color: grey;
}
.water-mark-2 {
    position: absolute; 
    top: 512px; 
    right: 160px;
    opacity: .2;
    font-weight: bold;
    font-size: 3rem;
    z-index: 0;
    transform: rotateZ(0deg);
    color: grey;
}
.water-mark-3 {   
    position: absolute; 
    top: 969px; 
    left: 50px;
    opacity: .2;
    font-weight: bold;
    z-index: -20;
    font-size: 3rem;
    transform: rotateZ(0deg);
    color: grey;
}

li {
    list-style-type: none;
    font-size: 15px;
    color: rgb(105, 105, 105);
    text-align:center;
}



#our_process {
    height: fit-content;
    background-color: #ffffff;
}

.fourth-h2 {
    color: rgb(56, 56, 56);
  padding-top: 30px;
}
.fourth-p {
    font-size: 15px;
    color: rgb(34, 34, 34);
    text-align: center;
}

.fifth-h1-1 {
    margin-top: -10px;
    font-weight: 800;
    color: #e1e0e2;
    font-size: 30px;
}
.fifth-h1-2 {
    margin-top: -10px;
    font-weight: 600;
    color: #401793;
    font-size: 40px;
}
.fifth-h1-3 {
    margin-top: -10px;
    font-weight: 500;
    color: #064b72;
    font-size: 25px;
}
.fifth-h1-4 {
    margin-top: 1px;
    font-weight: 300;
    color: #080808;
    font-size: 20px;
}

.fa-laptop,.fa-laptop-medical,.fa-chart-line {
    /* outline: 2px solid #301c58; */
    padding: 20px;
    margin: 10px;
    color: #301c58;
    transition: all .2s ease-in-out;
    border-radius: 70px;
}

.fa-laptop:hover, .fa-laptop-medical:hover, .fa-chart-line:hover {
    color: #301c58;
    /* outline: 2px solid rgb(231, 85, 0); */
    transform: scale(.9);
}

/* #three-service {
    box-shadow: 3px 2px 65px rgb(131, 131, 131,.2);
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 5px;
} */
#three-service-tech {
     box-shadow: 3px 20px 25px rgb(131, 131, 131,.1)
               ,inset  19px 2px 165px rgb(131, 131, 131,.1);
    ; 
    background-color: rgb(212, 212, 212);
    border: 1px solid rgb(102, 101, 101,.1);
    padding: 10px;
    border-radius: 5px;
    transition: .3s all ease-in-out;

}
/* #three-service-tech:hover {
    transform: scale(1.1); */
    /* box-shadow: 3px 2px  65px rgb(131, 131, 131,.2)
               ,inset 19px 2px 265px rgb(131, 131, 131,.4);
    ; */
    /* background-color: rgb(251, 251, 251); */

/* } */

.fifth-h3 {
    font-weight: 500;
    font-size: 20px;
    color: #301c58;
   
}
.sixth-h3 {
    color: seashell;
}
.full-footer {
    background: linear-gradient(rgb(25, 26, 36),#1b1820);
}
#footer {
    height: 60vh;
}
.ft {
    overflow: hidden;
}
#footer-2 {
    background: linear-gradient(rgb(25, 26, 36),#1b1820);}
address {
    color: seashell;
    font-family:'Times New Roman', Times, serif;
}
.footer-p {
    font-family:'Times New Roman', Times, serif;
    color: seashell;
    font-weight: 800;
    font-size: 18px;
}
.fa-facebook-square, .fa-twitter-square,.fa-instagram-square {
    color: seashell;
    font-size: 28px;
    margin-left: 2px;
}
.footer-btn {
    width: 210px;
    outline: seashell 1px solid;
    border: none;
    background-color: transparent;
    color: seashell;
    padding: 3px;
    transition: .3s all ease-in-out;
}
.footer-btn:hover {
    background-color: orangered;
    outline: none;
}

.Lm-btn {
    width: 140px;
    padding: 4px;
    /* outline: 1px solid orangered; */
    border: none;
    color: orangered;
    font-size: 12px;
    margin-top: 24px;
    transition: .2s all;
}
.Lm-btn:hover {
    width: 140px;
    /* padding: 8px; */
    outline: none;
    background-color: #255bf7;
    border: none;
    color: rgb(236, 236, 236);
    margin-top: 24px;
    transition: .2s all;
}
.sp-btn {
    width: 140px;
    padding: 8px;
    outline: none;
    background-color: rgb(151, 149, 149,.4);
    border: none;
    color: rgb(5, 5, 5);
    margin-top: 24px;
    transition: .3s all;
}
.sp-btn:hover {
    width: 140px;
    padding: 8px;
    outline: none;
    background-color: #255bf7;
    border: none;
    color: rgb(179, 179, 179);
    margin-top: 24px;
    transition: .2s all;
}
:root {
    color:  "#255bf7";
}
.f-li {
    color: white;
    font-size: 11px;
    margin-left: -20px;
}
.process-h3 {
    color: seashell;
    font-size: 15px;
    font-weight: 500;
}
.proccess-btn {
    background-color: transparent;
    outline: springgreen 1px solid;
    padding:  12px 16px;
    line-height: 1rem;
    border: none;
    color: springgreen;
    border-radius: 120px;
}



.display-6,.display-5 {
    font-weight: bold;
}
p {
    font-size: 13px;
}

.our_heading {
    font-weight: 600;
    font-size: 21px;
}
.link {
    color: #ffffff;
}
.link:hover {
    color: black;
    transition: .4s all;
    background-color: rgb(235, 233, 233);
    transform: scale(.9);
    font-weight: 500;
}
.dropdown-menu {
    background-color: rgb(22, 22, 22,.4);
    display: flex;
    flex-direction: column;
    justify-content: start;
}



#dropdown_1 {
    display: none;
}
#dropdown_2 {
    display: none;
}
.heading {
    margin-top: 50px;
    /* z-index: 2000; */
    font-family: 'Open Sans',Helvetica,Arial, sans-serif;
    line-height: 1.2;
    font-weight: 600;
    font-size: 3.4rem;
    color: #E9E9E9;
}


/* @media only screen and (max-width: 500px) {
    .home_image {
        width: 410px;
        margin-left: -112.5px;
    }
} */
/* @media only screen and (max-width: 600px) {
    .home_image {
        width: 460px;
        margin-left: -134px;
    }
} */

.carousel-control-next,.carousel-control-prev {
    z-index: 2000000;
}

#our_service {
    background: linear-gradient(to left bottom,rgba(40, 40, 70), rgb(43, 43, 53,.3));
}

#footer_map::-webkit-scrollbar {
    display: none;
}

.img-fluiid {
    border-radius: 3px;
}

.navigation-btn {
    display: none;
    position: fixed;
    box-shadow: 2px 2px 300px rgb(83, 83, 83,4);
    top: 560px;
    right: 10px;
    /* outline: rgb(0, 0, 0) 3px solid; */
    border: none;
    border-radius: 80px;
    width: fit-content;
    background: whitesmoke;
    color: rgb(20, 20, 20);
    font-weight: 400;
    font-size: 18px;
    z-index: 2000000;
    padding: 7px 14px;
    animation: rotate infinite 2s ;
    transition: .6s all;
}
.navigation-btn:hover {
    background-color: rgb(255, 255, 255);
    transform: scale(.9);
}
.navigation-btn:focus {
    transform: rotateZ(360deg);
    position: fixed;
    width: 600px;
    top: 560px;
    right: 10px;
    outline: rgb(255, 145, 1) 3px solid;
    border: none;
    border-radius: 60px;
    width: fit-content;
    background: whitesmoke;
    color: rgb(248, 127, 15);
    font-weight: 400;
    font-size: 18px;
    z-index: 2000000;
    padding: 10px 20px;
    animation: rotate infinite 2s ;
    transition: .6s all;
}
.learn-more {
    color: orangered;
}
.learn-more:hover {
    color: rgb(228, 228, 228);
}


.copy-text {
    color: rgb(167, 167, 167);
}
.seltam-text {
    color: rgb(240, 118, 61);
}
@keyframes rotate {
    from{
        transform: scale(1.1);
    }
    to{
        transform: scale(1);
    }
    10% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1.1);
    }
}

.border-bg {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid rgb(187, 187, 187,.4);
    background-color: rgb(255, 255, 255);
}
.our-service {
    background-color: rgb(246, 246, 246);
}
.paragraph-wwe {
    color: black;
    font-size: 18px;
}

/* footer new css */

#footer {
    background-color: #131313;
    height: fit-content;
    /* height: 40vh; */
}
.h4 {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 2.1rem;
    letter-spacing: 1px;
}
.h3 {
    color: #fff;
    font-weight: 400;
    font-size: 17px;
    line-height: 2.1rem;
    letter-spacing: 0.6px;
}
.footer-orange-btn {
    text-transform: uppercase;
    background: #f0632c;
    z-index: 99;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    outline: none;
    border: none;
    padding: 10px 20px;
    letter-spacing: .03rem;
    border-radius: 4px;
}
.footer-para {
    color: #6a6a6a;
    font-weight: 400;
    font-size: 16px;
    /* letter-spacing: 1px; */
    line-height: 1.7rem;
}
.footer-p-link {
    color: #6a6a6a;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    /* letter-spacing: 1px; */
    line-height: 1rem;
}

.footer-twitter-btn {
    padding: 4px 20px;
    border: 2px solid #1da1f2;
    border-radius: 4px;
    color: #f1f1f1;
    outline: none;
    width: 130px;
    background: #1da1f2;
}

.footer-fb-btn {
    padding: 4px 20px;
    border: 2px solid #1da1f2;
    border-radius: 4px;
    color: #1da1f2;
    outline: none;
    width: 130px;
    background: transparent;
}

.bg-img-holder {
    background: linear-gradient(160deg,rgba(0, 0, 0, 0.9), rgb(44, 41, 41,.4)),url(./assest/seltam_img/websiteredesigning.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.h2 {
    color: #fafafa;
    font-weight: 300;
    line-height: 1.3;
    font-size: 2.3647rem;
}

.bold-txt {
    font-weight: 500;
    font-size: 30px;
    word-spacing: 2px;
    letter-spacing: .5px;
}

.wwe-p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
}
.wwe-p-white {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #efef;
}
.wwe-p-c {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: center;
}
.wwe-h3 {
    line-height: 1.2;
    font-size: 1.77689rem;
    font-weight: 500;
    /* color: #0a2d63; */
    color: #394387;
}

.border-box {
    border: 1px solid rgb(153, 151, 151,.4);
    border-radius: 5px;
    padding: 50px 30px;
}
.border-down {
    border-bottom: rgb(153, 151, 151,.4) 1px solid;
    width: 10%;
    
}
.wwe-p-service  {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: justify;
}
@media only screen and (max-width: 900px) {
    .water{
      display: none;
    }
  }
.water {
   /* min-height: 100px; */
    min-width: 400px;
    -webkit-transform: rotate(-90deg);
    letter-spacing: 1px;
    /* transform: translateY(36px); */
    color: #6a6a6a;
    opacity: .5;
    font-weight: 700;
}

/* @media only screen and (max-width: 600px) {
   
  } */

.homewebapp-img {
    border-radius: 8px;
}
.recruit-btn {
    padding: 10px 10px;
    border-radius: 19px;
    letter-spacing: 1.1px;
    width: 160px;
    border: none;
    outline: none;
    background-color: #394387;
    color: #efefef;
    font-size: 14px;
    box-shadow: 1px 1.1px 2px  rgba(136, 136, 136, 0.3);
    transition: all .5s;
}


.recruit-btn:hover {
    padding: 10px 10px;
    border-radius: 19px;
    letter-spacing: 1.1px;
    width: 160px;
    border: 2px solid #394387;
    outline: none;
    background-color:transparent;
    color: #394387;
    font-size: 14px;
    box-shadow: 1px 1.1px 2px  rgba(136, 136, 136, 0.3);
    transition: all .5s;
}

.blend-img {
    border-radius: 8px;
}
.border-orange {
    border-bottom: 1.3px solid rgb(231, 97, 48);
    width: 30%;
}


.img-software-1 {
    background: url(./assest/seltam_img/e_com_2.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-2 {
    background: url(./assest/seltam_img/CRM_1.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-3 {
    background: url(./assest/seltam_img/emd_new.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-4 {
    background: url(./assest/seltam_img/maintenance.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.software-border {
    border-bottom: 1px solid rgb(112, 111, 111,.4);
    width: 50%;
}


