/* .parent-list {
    display: flex;
    text-align: left;
}
.dropdown-parent {
    font-size: 18px;
    color: #000;
    padding: 10px 20px;
    background: #484848;
}
.dropdown-item {
    font-weight: 700;
    font-size: 16px;
    color: black;
} */

.dropdown-box {
    background-color: rgb(34, 33, 33,.4);
    position: absolute;
    top: 10px;
}

  .dropdown_bg {
    background: #180f27;
    text-align: left;
  }
  .dr-a  {
      text-decoration: none;
      color: rgb(214, 214, 214);
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      /* padding: 100px; */
  } 
  .x {
      display: flex;
      justify-content: flex-start;
      flex-direction:column;
      text-align: left;
  }
 
  .dropdown-link {
      text-align: left;
  }
  .dr-link {
      font-size: 18px;
      cursor: pointer;
  }
  .dr-hover:hover {
      color: #00bfff;
      transition: .3s all;
      transform: scale(1.1);
  }