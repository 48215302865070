
:root {
    --blue-color: #110874;
    --second-color :#43121d;
    --third-color: #f98169;
    --fourth-color:#555;
    --para-tag: #555;
  }

  .payroll_img {
    background: linear-gradient(to left bottom,rgba(46, 46, 47, 0.5), rgba(13, 13, 14, 0.4)),url(./assest/seltam_img/Payrolloutsourcing.jpg);
    background-size: cover;
    height: 80vh;
}
.rs-h4 {
    /* margin-top: -10px; */
    font-weight: 600;
    color: #401793;
    font-size: 40px;
}
/* .below-container-wd {
    color: #255bf7;
    font-weight: 600;
    font-size: 45px;
} */
.rs-h-small {
    font-weight: 500;
    color: #401793;
    font-size: 30px;
}
.rs-h-t-small {
    font-weight: 600;
    color: #401793;
    font-size: 21px;
}

.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: center;
}
.paragraph-j {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.paragraph-white {
    font-size: 15px;
    color: rgb(189, 189, 189);
    text-align: justify;
}
.p-highlight {
    font-size: 15px;
    color: #403a4d;
    font-weight: 500;
}
.rs-h6 {
    /* margin-top: -10px; */
    font-weight: 500;
    color: #401793;
    font-size: 25px;
}

.flex {
    padding: 50px 20px;
    background-color: #110874;
}

/* for payroll grid */

.payroll-grid {
    padding: 28px;
    min-height: 220px;
    width: 400px;
    border-radius: 8px;
    background-color: rgb(228, 228, 228);
}
.border-recruit {
    border-bottom: 1px solid #7f7f7f;
    opacity: .5;
    width: 30%;
}