.footer_bg {
    background-color: rgb(26, 29, 48);
    height:fit-content;
    padding: 10px;
}
.f-link {
    color: honeydew;
    font-weight: 400;
    /* margin-left: 15px; */
    font-size: 15px;
}
