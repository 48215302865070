.contact_img {
    background: linear-gradient(  to right bottom,rgba(5, 5, 7, 0.9), rgba(158, 63, 182, 0.8)),url(./assest/seltam_img/contact_new\ \(1\).jpg);
    background-size: cover;
    margin-bottom: -20px;
    /* height: fit-content; */
    padding: 50px;
}
.display-2 {
    z-index: 12;
}
.contact {
    z-index: 30000;
}
.c-p {
    font-size: 12px;
    color: rgb(202, 202, 202);
}

.fa-handshake {
    position: absolute;
    top: 70%;
    right:0px;
    color: rgb(255, 255, 255);
    border-radius: 0px;
    padding: 15px;
    font-size: 37px;
    /* outline: 1px solid rgb(165, 164, 164,.1); */
    border-radius: 10px 5px;
    /* background: linear-gradient(  to right bottom,rgba(240, 240, 240, 0.9), rgba(158, 63, 182, 0.8)); */
    background-color: transparent;
}
.fa-handshake:hover {
    transition: .3s all ease;
    transform: scale(.9);
    color: rgb(27, 189, 189);
}

.pop_up_model {
    position: absolute;
    top: 20px;
    left: 3rem;
}

.c-input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 21px;
    border-bottom: 1px rgb(167, 167, 167,.5) solid;
    width: 260px;
    font-size: 19px;
    padding: 10px;
}
.c2-input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 21px;
    border-bottom: 1px rgb(167, 167, 167,.5) solid;
    width: 540px;
    font-size: 19px;
    padding: 10px;
}
.c-button {
    color: azure;
    font-weight: 500;
    font-size: 19px;
    background-color: rgb(32, 32, 48);
    outline: none;
    border: none;
    padding: 8px 10px;
}
/* .c-i  {
    margin-top: 12px;
} */
.ico {
    font-size: 11px;
}

.c2-form {
    width: 300px;
}

#body::-webkit-scrollbar {
    display: none;
  }
  