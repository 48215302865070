.career_img {
    background: linear-gradient(to left bottom,rgba(48, 49, 53, 0.9), rgba(13, 13, 14, 0.7)),url(./assest/seltam_img/career.jpg);
    background-size: cover;
    background-position: center;
    height: 90vh;
}
.career_img-apply-now {
    background: linear-gradient(to left bottom,rgba(48, 49, 53, 0.9), rgba(13, 13, 14, 0.7)),url(./assest/seltam_img/career.jpg);
    background-size: cover;
    background-position: center;
    height: 40vh;
}
.fifth-h1-1 {
    /* color: seashell; */
    color: #e4e5e7;
    font-weight: 800;
    font-size: 40px;
    
}
.form-bg {
  background-color: rgb(255, 255, 255);
}
.btn-for-career {
    outline: none;
    border: none;
    background: rgb(13, 118, 189);
    width: 160px;
    padding: 12px 6px;
    color: rgb(213, 217, 219);
}
.career-full {
    background-color: rgb(110, 110, 110,.2);
    height: 1000px;
}