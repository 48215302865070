.dig_img {
    background: linear-gradient(to left bottom,rgba(7, 7, 7, 0.7), rgba(13, 13, 14, 0.5)),url(./assest/seltam_img/homedigitalmarketing.jpg);
    background-size: cover;
    height: 80vh;
}
.bg-blue-digital-marketing {
    background-color: rgb(41, 71, 128);
    height: 50vh;
}
.bg-blue-digital-marketing-1 {
    background: url(./assest/seltam_img/magnifying-glass-with-seo-concepts_1134-81.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-2 {
    background: url(./assest/seltam_img/marketing-social-media-background-with-funny-elements_1361-1264.jpg);
    background-position:top;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-3 {
    background: url(./assest/seltam_img/ContentMarketing.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-4 {
    background: url(./assest/seltam_img/1000_F_122058573_CGQ3wB9S2HljFJW1lLzxwziQEvZ5q8At.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-5 {
    background: url(./assest/seltam_img/MobileAdvertising.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-6 {
    background: url(./assest/seltam_img/othermarketing.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.below-h2-1-white {
    color: #efefef;
}
.digi-border {
    border-bottom: 1px solid white;
}