.web_img {
    background: linear-gradient(to left bottom,rgba(66, 100, 212, 0.1), rgba(13, 13, 14, 0.7)),url(./assest/seltam_img/webdesign.jpg);
    background-size: cover;
    background-position: center;
    height: 80vh;
}

.border {
    border-bottom: grey 2px solid;
}
#border-bottom {
    border-bottom: 2px solid rgb(2, 8, 66);
}
.img-fluid2 {
    height: 400px;
}
.para-text {
    font-size: 15px;
    word-spacing: 2px;
}