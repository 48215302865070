.mob_img {
    
        background: linear-gradient(to left bottom,rgba(43, 44, 46, 0.5), rgba(13, 13, 14, 0.7)),url(./assest/seltam_img/appdevelopment.jpg);
        background-size: cover;
        background-position: center;
        height: 80vh;
    
}
.para-text {
        font-size: 15px;
        word-spacing: 2px;
    }

.heading {
        margin-top: 50px;
        /* z-index: 2000; */
        font-family: 'Open Sans',Helvetica,Arial, sans-serif;
        line-height: 1.2;
        font-weight: 600;
        font-size: 3.4rem;
        color: #E9E9E9;
}

.image-banner {
        background: linear-gradient(160deg,rgba(15, 15, 15, 0.6),rgb(20, 20, 20,.3)),url(./assest/seltam_img/mobilemainimage.jpg);
        height: 80vh;
        background-size: cover;
        background-position: center;
}
.paragraph-j1 {
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        letter-spacing: -1px;
        /* word-spacing: 1px; */
        z-index: 3000;

}
.native-text {
        font-size: 17px;
        font-weight: 200;
        color: rgb(207, 207, 207);
}