/*font family*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");

* {
  margin: 0px;
  padding: 0px;
  /* box-sizing: border-box; */
  font-family: "Poppins", sans-serif;
}
:root {
  --blue-color: #110874;
  --second-color: #43121d;
  --third-color: #f98169;
  --fourth-color: rgb(114, 113, 113);
}

/* .about_heading_h3,.about_heading_h3_span {
      color: var(--blue-color);
      font-family: 'Nanum Myeongjo', serif;
    } */
/* .about_heading_h3 {
        color: var(--third-color);
    } */
.about_heading_h3 {
  color: var(--third-color);
  font-size: 2rem;
}

/* .about_img_1 {

    } */
/* .about_img_2 {
        transform: scale(.9);
        transition: .3s all ease-in-out;
    } */
/* .about_img_1:hover {
        transform: scale(.8);
    } */

.about_container {
  /* margin-top: -80px; */

  background: linear-gradient(160deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url(./assest/seltam_img/about.jpg) no-repeat;
  /* background-position: center; */
  background-size: cover;
  /* box-shadow: 3px 4px 10px rgb(138, 138, 138,.3); */
  height: 80vh;
}
.smart-heading {
  color: white;
}
.pro {
  color: #110874;
  font-size: 30px;
}
.smart-child-heading {
  color: rgb(253, 253, 31);
  font-family: "Balsamiq Sans", cursive;
  font-size: 3rem;
}

.heading {
  font-size: 4rem;
  color: var(--third-color);
}
.vision {
  color: #110874;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}
.hyper_link {
  transition: all 0.3s;
}

.fifth-h3 {
  font-weight: 500;
  font-size: 20px;
  color: #301c58;
}
.fifth-mv {
  /* color: seashell; */
  color: #9b9b9b;
  font-weight: 800;
  font-size: 40px;
}

.about_p {
  /* font-family: 'Karla', sans-serif; */
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  text-transform: capitalize;
  text-align: justify;
}
.about_p_white {
  /* font-family: 'Karla', sans-serif; */
  font-size: 15px;
  font-weight: 400;
  color: #f0ecec;
  text-transform: capitalize;
  text-align: justify;
}
.two-about {
  background-color: #2f2a53;
}

/* background-color: #2f2a53 */
.border-heading {
  border-bottom: 1px solid #9b9b9b;
}
.wwe-p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
}
.wwe-p-white {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #efef;
}
.wwe-p-c {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: center;
}
.wwe-h3 {
    line-height: 1.2;
    font-size: 1.77689rem;
    font-weight: 500;
    /* color: #0a2d63; */
    color: #394387;
}

.border-box {
    border: 1px solid rgb(153, 151, 151,.4);
    border-radius: 5px;
    padding: 50px 30px;
}
.border-down {
    border-bottom: rgba(34, 34, 34, 0.4) 1px solid;
    width: 10%;
    
}
.wwe-p-service  {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: justify;
}
/* .images-of-about {
    background: url(./assest/seltam_img/Vision.jpg);
} */
.vision-img {
    border-radius: 6px;
}
.border-orange {
    border-bottom: 1.3px solid rgb(231, 97, 48);
    width: 30%;
}
.about-btn {
    color: #efefef;
    background-color: #301c58;
    font-weight: 300;
    padding: 7px 24px;
    border: none;
    border-radius: 6px;
    outline: none;
}
.end-flex {
    background: linear-gradient(100deg,rgb(24, 23, 23,.9), rgb(8, 8, 8,.8)),url(./assest/seltam_img/pexels-ivy-son-3490393.jpg);
    background-position: center;
    background-size: cover;
    height: fit-content;
    padding: 50px 80px;
}
.border-flex {
    border: 2px solid rgb(97, 97, 97);
    text-align: center;
    border-radius: 3px;
}