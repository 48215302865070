
  .recruit_img {
    background: linear-gradient(to left bottom,rgba(10, 10, 10, 0.5), rgba(13, 13, 14, 0.7)),url(./assest/seltam_img/Recruitment.jpg);
    background-size: cover;
    height: 80vh;
}
.rs-h-t-small {
  font-weight: 600;
  color: #401793;
  font-size: 21px;
}
.rs-h-small {
  font-weight: 600;
  color: #401793;
  font-size: 30px;
}

.recruit-grid {
  /* padding: 8px; */
  /* width: 400px; */
  border-radius: 7px;
  /* background-color: rgb(228, 228, 228); */
  background: rgb(88, 88, 88,.1);
  outline: 0.6px solid rgba(139, 138, 138, 0.3);
}