:root {
    --blue-color: #110874;
    --second-color :#43121d;
    --third-color: #f98169;
    --fourth-color:#555;
    --para-tag: #555;
  }


.service_heading {
    color:var(--third-color);
}
.service_heading-span {
    color: var(--blue-color);
    font-family: 'Nanum Myeongjo', serif;
}
.service_img {
    background: linear-gradient(rgba(66, 100, 212, 0.5), rgb(3, 3, 126,.5)),url(./assest/seltam_img/services.jpg);
    background-size: cover;
    height: 80vh;
}

.service-child-heading {
    font-family: 'Balsamiq Sans', cursive;
    color: rgb(255, 216, 42);
    font-size: 3rem;
}
.wd-h6 {
    /* margin-top: -10px; */
    font-weight: 500;
    color: #403a4d;
    font-size: 40px;
}
.wd-h4 {
    /* margin-top: -10px; */
    font-weight: 800;
    color: #401793;
    font-size: 50px;
}
.wd-h-small {
    font-weight: 600;
    color: #401793;
    font-size: 35px;
}
.wd-h-t-small {
    font-weight: 500;
    color: #401793;
    font-size: 21px;
}
.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.p-highlight {
    font-size: 15px;
    color: #401793;
    font-weight: 500;
}
.img-fluid-2 {
    border-radius: 200px 100px 100px 10px;
    transition: all .3s;
    outline: rgb(15, 15, 61,.3) 2px solid;
    z-index: -15;
}
.img-fluid-1 {
    border-radius: 500px 50px 50px 50px;
    transition: all .3s;
    outline:  rgb(15, 15, 61,.3)  2px solid;
    z-index: -15;
}
.img-fluid-2:hover {
    /* border-radius: 200px 100px 500px 10px; */
    box-shadow: 2px 3px 4px 10px rgb(22, 22, 22,.2);
    outline: none;
}
.img-fluid-1:hover {
    /* border-radius: 200px 100px 500px 10px; */
    box-shadow: 2px 3px 4px 10px rgb(15, 15, 15,.2);
    outline: none;
}
.wd_btn {
    padding: 10px;
    width: 140px;
    color: #110874;
    background-color: transparent;
    outline: 2px solid #110874;
    border: none;
    border-radius: 5px 2px 5px 2px;
    font-weight: 600;
    transition: all .3s;
}
.wd_btn:hover {
    padding: 10px;
    width: 140px;
    color: #efeff1;
    background-color: #110874;
    outline: none;
    border: none;
    border-radius: 5px 2px 5px 2px;
    font-weight: 600;
}